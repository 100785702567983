import React from "react";

const ProjectTitle = ({ color }) => (
  <div className="projects-page__project-title" style={{ color }}>
    {/* eslint-disable jsx-a11y/heading-has-content */}
    <h2 className="projects-page__project-title-name"></h2>

    <div className="projects-page__project-title-tagline-wrapper">
      <div className="projects-page__project-title-tagline"></div>
    </div>
  </div>
);

export default ProjectTitle;
