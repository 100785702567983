import classnames from "classnames";
import { cubicInOut } from "eases";
import { graphql } from "gatsby";
import throttle from "lodash.throttle";
import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Container from "@components/Container";
import FadeInOnView from "@components/FadeInOnView";
import Footer from "@components/Footer";
import clamp from "@utils/clamp";
import getTextRows from "@utils/getTextRows";

import Project from "./Project";
import ProjectTitle from "./ProjectTitle";
import "./style.css";

class Work extends Component {
  constructor(props) {
    super(props);

    this.state = { index: -1 };
    this.projects = [];
    this.titles = [];
    this.taglinesCopy = [];

    this.animation = {
      raf: null,
      startExitTime: null,
      startEnterTime: null,
      exitDuration: 400,
      enterDuration: 600,
      rowDelay: 80,
      taglineRowsCount: 1,
    };
  }

  getProjects = () => {
    const { data } = this.props;

    return data?.wpgraphql?.projects?.nodes || [];
  };

  animate = t => {
    if (!this.animation.startExitTime) {
      this.animation.startExitTime = t;
    }

    let deltaTime = t - this.animation.startExitTime;
    const isEntering =
      this.animation.startEnterTime ||
      deltaTime >=
        this.animation.exitDuration +
          (this.animation.taglineRowsCount - 1) * this.animation.rowDelay;

    if (isEntering) {
      if (!this.animation.startEnterTime) {
        this.animation.startEnterTime = t;

        const taglineRows =
          this.taglinesCopy[this.animation.targetData.index] ?? [];
        this.animation.taglineRowsCount = taglineRows.length;

        // switch data
        this.titles.forEach(({ project, name, tagline, index }) => {
          if (index > -1) {
            if (index !== this.animation.targetData.index) {
              project.style.zIndex = 1;
            } else {
              project.style.zIndex = 2;
            }
          }

          name.innerText = this.animation.targetData.name;
          tagline.innerHTML = taglineRows.reduce(
            (acc, row) =>
              acc +
              `<div class="projects-page__project-title-tagline-row"><span style="transform: translateY(100%)">${row}</span></div>`,
            ""
          );
        });
      }
      deltaTime = t - this.animation.startEnterTime;
    }

    // calc progress for each row
    const progresses = [];
    const currentDuration = !isEntering
      ? this.animation.exitDuration
      : this.animation.enterDuration;
    for (let i = 0; i < this.animation.taglineRowsCount; i++) {
      progresses.push(
        cubicInOut(
          clamp(
            (deltaTime - i * this.animation.rowDelay) / currentDuration,
            0,
            1
          )
        ).toFixed(4)
      );
    }

    this.titles.forEach(({ name, tagline }) => {
      const taglineRows = [...tagline.children];

      if (!isEntering) {
        name.style.opacity = 1 - progresses[0];

        // apply transform to each row
        taglineRows.forEach((taglineRow, i) => {
          taglineRow.firstChild.style.transform = `translateY(${-100 *
            progresses[i]}%)`;
        });
      } else {
        name.style.opacity = progresses[0];

        // apply transform to each row
        taglineRows.forEach((taglineRow, i) => {
          taglineRow.firstChild.style.transform = `translateY(${100 *
            (1 - progresses[i])}%)`;
        });
      }
    });

    // raf hasn't interrupted
    if (this.animation.raf) {
      if (
        // is doing first part of animation
        !isEntering ||
        // entering hasn't finished yet
        deltaTime <
          this.animation.enterDuration +
            (this.animation.taglineRowsCount - 1) * this.animation.rowDelay
      ) {
        this.animation.raf = requestAnimationFrame(this.animate);
      } else {
        // reset animation data
        this.animation.startExitTime = null;
        this.animation.startEnterTime = null;

        if (
          // check for chain reaction
          this.animation.nextInQueueData &&
          (this.animation.nextInQueueData.name !==
            this.animation.targetData.name ||
            this.animation.nextInQueueData.tagline !==
              this.animation.targetData.tagline)
        ) {
          this.animation.targetData = { ...this.animation.nextInQueueData };
          this.animation.nextInQueueData = null;
          this.animation.raf = requestAnimationFrame(this.animate);
        } else {
          this.animation.raf = null;
          this.animation.targetData = null;
          this.animation.nextInQueueData = null;
        }
      }
    }
  };

  updateIndex = throttle(index => {
    const projects = this.getProjects();

    if (index < 0 && index >= projects.length) {
      return;
    }

    this.setState({ index });

    const activeProject = projects[index];
    const activeProjectCover =
      activeProject && activeProject.blocks
        ? activeProject.blocks.find(
            obj => obj.__typename === "WPGraphQL_AcfProjectCoverBlock"
          )
        : null;
    const activeProjectName = activeProjectCover?.acf?.projectName ?? "";

    if (!this.animation.raf) {
      this.animation.targetData = {
        index,
        name: activeProjectName,
      };
      this.animation.raf = requestAnimationFrame(this.animate);
    } else {
      // reserve next animation
      this.animation.nextInQueueData = {
        index,
        name: activeProjectName,
      };
    }
  }, 1200);

  handleScroll = () => {
    const midView = window.scrollY + window.outerHeight * 0.2666;
    let index = 0;
    let previousDistance = Infinity;

    while (index < this.projects.length) {
      const thisMid =
        this.projects[index].offsetTop + this.projects[index].offsetHeight / 2;
      const thisDistance = Math.abs(thisMid - midView);

      if (previousDistance < thisDistance) {
        break;
      } else {
        previousDistance = thisDistance;
        index++;
      }
    }

    index--;

    if (this.state.index !== index) {
      this.updateIndex(index);
    }
  };

  handleResize = () => {
    const projects = this.getProjects();
    this.taglinesCopy = projects.map(p => {
      const coverBlock = p.blocks
        ? p.blocks.find(
            obj => obj.__typename === "WPGraphQL_AcfProjectCoverBlock"
          )
        : null;
      const tagline = coverBlock?.acf?.tagline ?? "";

      if (!tagline.length) {
        return [""];
      }

      return getTextRows(tagline, this.titles[0].tagline);
    });
  };

  componentDidMount() {
    this.projects = [...document.querySelectorAll(".projects-page__project")];
    this.titles = [
      document.querySelector(".container > .projects-page__project-title"),
      ...document.querySelectorAll(
        ".projects-page__project .projects-page__project-title"
      ),
    ].map((t, i) => ({
      index: i - 1,
      project: this.projects[i - 1],
      title: t,
      name: t.querySelector(".projects-page__project-title-name"),
      tagline: t.querySelector(".projects-page__project-title-tagline"),
    }));

    window.addEventListener("resize", this.handleResize);
    document.fonts.ready.then(this.handleResize);

    // add listener on scroll to evaluate current index
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    this.handleScroll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.transitionStatus !== this.props.transitionStatus) {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent("checkHeaderColor"));
        window.dispatchEvent(new CustomEvent("checkSideNavColor"));
      }, 20);
    }
  }

  componentWillUnmount() {
    // remove listener on scroll to evaluate current index
    window.removeEventListener("scroll", this.handleScroll);

    if (this.animation.raf) {
      this.animation.raf = cancelAnimationFrame(this.animation.raf);
    }
  }

  render() {
    const { transitionStatus, data } = this.props;
    const projects = this.getProjects();

    return (
      <>
        <Helmet title={"Work | " + data.site.siteMetadata.title} />
        <FadeInOnView
          className={classnames("page projects-page", {
            "projects-page--exiting":
              transitionStatus === "exiting" || transitionStatus === "exited",
          })}
        >
          <Container variant="full">
            <ProjectTitle />

            {projects &&
              projects.map((project, i) => {
                return (
                  <Project
                    key={project.uri}
                    index={i}
                    uri={project.uri}
                    blocks={project.blocks}
                    active={i === this.state.index}
                    title={
                      <ProjectTitle
                        color={project.acf?.titleColourImageOverlap}
                      />
                    }
                  />
                );
              })}
          </Container>
        </FadeInOnView>

        <Footer />
      </>
    );
  }
}

export default Work;

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    wpgraphql {
      projects(first: 100) {
        nodes {
          uri
          acf {
            titleColourImageOverlap
          }

          blocks {
            ... on WPGraphQL_AcfProjectCoverBlock {
              parentId
              acf {
                image {
                  sourceUrl(size: _1536X1536)
                }
                projectName
                tagline
              }
            }
          }
        }
      }
    }
  }
`;
