const getTextRows = (text, container) => {
  if (!container) {
    return;
  }
  if (!text.length) {
    return;
  }

  const rows = [];
  let currentRowContent = text;

  // save previous content
  const previousContent = container.innerHTML;

  // set nowrap
  container.style.whiteSpace = "nowrap";
  container.innerText = currentRowContent;

  while (currentRowContent.length) {
    while (container.scrollWidth > container.offsetWidth) {
      const lastBreakSpotIndex = currentRowContent.lastIndexOf(" ");

      if (lastBreakSpotIndex < 0) {
        // there is no way to break. Give up
        break;
      }

      currentRowContent = currentRowContent.substring(0, lastBreakSpotIndex);
      container.innerText = currentRowContent;
    }

    rows.push(currentRowContent);
    currentRowContent =
      rows.join("").length < text.length
        ? text.substring(rows.join("").length)
        : "";
    container.innerText = currentRowContent;
  }

  container.style.whiteSpace = null;
  container.innerHTML = previousContent;

  return rows;
};

export default getTextRows;
