import React from "react";
import classnames from "classnames";

import MorphLink from "@components/MorphLink";
import mod from "@utils/mod";
import usePreloadedImage from "@utils/usePreloadedImage";

const Project = ({ uri, blocks = [], index, title, active }) => {
  const cover = blocks.find(
    obj => obj.__typename === "WPGraphQL_AcfProjectCoverBlock"
  );
  const imageUrl = cover?.acf?.image?.sourceUrl ?? "";
  const { isLoaded: isImageLoaded } = usePreloadedImage(imageUrl);

  return (
    <div className="projects-page__project">
      <div
        className={`projects-page__project__image
                        projects-page__project__image--${mod(index, 6)}`}
        data-uri={uri}
      >
        {/* Image */}
        <MorphLink
          to={`/${uri}`}
          className="cursor-type--checkit"
          duration={2}
          delay={0.4}
          morph={{
            from: `.projects-page__project .projects-page__project__image[data-uri="${uri}"] .projects-page__project__image-inner`,
            to: ".project-cover .container",
          }}
        >
          <div
            className={classnames(
              "projects-page__project__image-inner",
              {
                "projects-page__project__image-inner--loaded": isImageLoaded,
              },
              {
                "projects-page__project__image-inner--active": active,
              }
            )}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          >
            <div className="projects-page__project__title-wrapper">{title}</div>
          </div>
        </MorphLink>
      </div>
    </div>
  );
};

export default Project;
